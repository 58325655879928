<template>
  <div>
    <b-card>
      <b-tabs align="left" class="tab-primary" v-model="tabIndex" :key="KeyTab">
        <b-tab lazy>
          <template #title>
            <div class="tab-title">
              <feather-icon icon="SendIcon" />
              <span> {{$t('Crear envío')}} </span>
            </div>
          </template>
          <create-deliveries @changeTab="changeTab" class="pt-1"></create-deliveries>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div>
              <feather-icon icon="FileTextIcon" />
              <span> {{$t('Generar etiquetas')}} </span>
            </div>
          </template>
          <generate-labels @changeTab="changeTab"/>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div>
              <feather-icon icon="FileTextIcon" />
              <span> {{$t('Con observación')}} </span>
            </div>
          </template>
          <observation @changeTab="changeTab" />
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div>
              <feather-icon icon="PrinterIcon" />
              <span> {{$t('Imprimir')}} </span>
            </div>
          </template>
          <print />
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div class="tab-title">
              <feather-icon icon="FileIcon" />
              <span> {{$t('Manifiesto')}} </span>
            </div>
          </template>
          <list-manifest />
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div class="tab-title">
              <feather-icon icon="TruckIcon" />
              <span> {{$t('Retiros')}} </span>
            </div>
          </template>
          <list-pickups />
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div>
              <feather-icon icon="FileTextIcon" />
              <span> {{$t('Impresos')}} </span>
            </div>
          </template>
          <printed />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import CreateDeliveries from './new-delivery/CreateDelivery.vue'
import GenerateLabels from './generate-labels/GenerateLabels.vue'
import Observation from './observation/Observation.vue'
import Print from './print/Print.vue'
import Printed from './printed/Printed.vue'
import ListManifest from '@/views/modules/manifest/List.vue'
import ListPickups from './pickups/ListPickups.vue'

export default {
  components: { CreateDeliveries, GenerateLabels, Observation, Print, Printed, ListManifest, ListPickups },
  data () {
    return {
      tabIndex: 0,
      KeyTab: 1,
      tabsName: {
        create: 0,
        labels: 1,
        rejected: 2,
        print: 3,
        summaries: 4,
        pickups: 5,
        printed: 6
      },
      isInitialMount: true
    }
  },
  mounted () {
    // Obtener el hash de la URL al cargar el componente
    const hash = window.location.hash.replace('#/', '').split('&')[0]
    // Verificar si el hash está en las claves de tabsName
    if (hash in this.tabsName) {
      // Actualizar el tabIndex basado en el hash
      this.tabIndex = this.tabsName[hash]
      this.updateTab()
    }
    this.isInitialMount = false
  },
  watch: {
    $route(to, from) {
      const hash = to.hash.replace('#/', '').split('&')[0]
      this.tabIndex = this.tabsName[hash]
      this.KeyTab++
    },
    tabIndex (newIndex, oldIndex, e) {
      const tabName = Object.keys(this.tabsName).find(key => this.tabsName[key] === newIndex)
      window.parent.postMessage({ action: 'updateHash', nuevoHash: `#/${tabName}` }, '*')
    }
  },
  methods: {
    changeTab(hash) {
      this.tabIndex = this.tabsName[hash]
    },
    updateTab() {
      // Obtener el nombre del tab basado en el newIndex
      const tabName = Object.keys(this.tabsName).find(key => this.tabsName[key] === this.tabIndex)
      // Actualizar la ruta basada en el nombre del tab
      window.location.hash = `#/${tabName}`
    }
  }
}
</script>